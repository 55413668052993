import { useState } from 'react';
import { useRouter } from 'next/router';

import { useTracking } from '@hooks/tracking';
import { useInterval } from '@hooks/interval';
import { useUser } from '@hooks/user';
import { useAuth } from '@hooks/auth';

import Modal from './Modal';

import * as Styled from './styles';

const contentList = [
  {
    id: 1,
    text: '+200 horas de aulas',
  },
  {
    id: 2,
    text: 'Análises do que é notícia',
  },
  {
    id: 3,
    text: 'Vídeos com insights e dicas',
  },
  {
    id: 4,
    text: 'Podcasts objetivos',
  },
  {
    id: 5,
    text: 'Eventos online',
  },
  {
    id: 6,
    text: 'E muito mais!',
  },
];

const ModalHome: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const { setShowModalLogin, showModalLogin } = useAuth();
  const [countOpenModal, setCountOpenModal] = useState(0);
  const router = useRouter();
  const { track } = useTracking();
  const { user } = useUser();

  const getTimeInterval = () => {
    if (openModal) {
      return null;
    }

    if (countOpenModal === 0) {
      return 30000;
    }

    return 120000;
  };

  useInterval(() => {
    if (!user?._id && !router.isFallback) {
      setOpenModal(true);
      setCountOpenModal(countOpenModal + 1);
    }
  }, getTimeInterval());

  function onClose() {
    track('closed_modal_home', {});
    setOpenModal(false);
  }

  return (
    <Modal open={openModal && !showModalLogin} onClose={onClose}>
      <Styled.Container>
        <Styled.Header>
          <Styled.Title>Faça parte da StartSe!</Styled.Title>
        </Styled.Header>
        <Styled.Body>
          <Styled.Content>
            <h3>Tenha acesso gratuito a:</h3>
            {contentList.map(item => (
              <Styled.ListItem key={item.id}>
                <img
                  src="https://startse-landings.s3.us-east-2.amazonaws.com/app/svg/blue-check.svg"
                  alt="Ícone de Check"
                />
                <p>{item.text}</p>
              </Styled.ListItem>
            ))}
            <Styled.Border />
          </Styled.Content>
          <Styled.Login>
            <h4>
              Todos os dias, fique por dentro do que importa <span>agora</span>{' '}
              para sua carreira e para o seu negócio.
            </h4>
            <Styled.SignupButton
              onClick={() => {
                setShowModalLogin(true, true);
              }}
            >
              Faça parte
            </Styled.SignupButton>
            <Styled.LoginButton>
              Caso já tenha conta,{/* eslint-disable-next-line */}
              <p
                onClick={() => {
                  setShowModalLogin(true, false);
                }}
              >
                clique aqui
              </p>
            </Styled.LoginButton>
          </Styled.Login>
        </Styled.Body>
      </Styled.Container>
    </Modal>
  );
};

export default ModalHome;
