import { IContentList } from '@models/content.interface';
import { formatContent } from '@utils/format';
import api from '../api';

export const getContentForCategories = async (
  slugs: string,
): Promise<IContentList[]> => {
  const { data } = await api.get('/contents/categories', {
    params: {
      limit: 10,
      offset: 0,
      slugs,
    },
  });
  const formattedContents = formatContent(data);

  return formattedContents;
};
